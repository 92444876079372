import { Pipe, PipeTransform } from '@angular/core';
import { IOrgUser, IUser, IOrgUserObj } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';
import { UtilsService } from '../services/utils.service';
import { ErrorSeverity } from '../services/error.service';
import { UserStoreService } from '../services/store/user-store.service';
import { RoleType } from '../enums/user/role-types.enum';

@Pipe({
  name: 'userNameOrEmail',
  standalone: true
})
export class UserNameOrEmailPipe implements PipeTransform {

  constructor(
    private uHelper: UserHelperService,
    private utilsService: UtilsService,
    private userStore: UserStoreService
  ) {}

  // not using 'CURRENT' in organizationId param bcs it introduces circular dependency
  transform(orgUsers: IOrgUser[], orgId: number | null, includeTitles: boolean, showEmail?: boolean): string;
  transform(orgUser: Partial<IOrgUser | IOrgUserObj>, includeTitles: boolean, showEmail?: boolean): string;
  transform(userOrUsers: Partial<IOrgUser | IOrgUserObj> | IOrgUser[], orgIdOrIncludeTitles?: number | boolean | null, maybeIncludeTitles?: boolean, showEmail: boolean = true): string {
    
    let user: Partial<IOrgUser | IOrgUserObj> | undefined | null;
    let includeTitles: boolean;

    if (Array.isArray(userOrUsers)) {
      const orgId = typeof orgIdOrIncludeTitles === 'number' ? orgIdOrIncludeTitles : null;
      includeTitles = maybeIncludeTitles as boolean;

      if (orgId === null) {
        this.utilsService.logError('orgId missing in userNameOrEmail Pipe, qw8ytruie', ErrorSeverity.ERROR);
        return '';
      }

      user = this.uHelper.getOrgUser(userOrUsers, orgId);
      if (!user && this.userStore.hasUserRole(RoleType.SYSTEM_ADMIN, null)) {
        user = userOrUsers.at(0); // fallback to first user --- should not happen - happens for sysAdmins bcs they access orgs where they are not members
      }
    } else {
      user = userOrUsers;
      includeTitles = orgIdOrIncludeTitles as boolean;
    }

    if (!user) {
      this.utilsService.logError(`User not found in userNameOrEmail Pipe, 8ruihjtq9a8r, ${JSON.stringify(userOrUsers)} ${orgIdOrIncludeTitles} ${maybeIncludeTitles} ${showEmail}`, ErrorSeverity.ERROR);
      return '';
    }

    return this.uHelper.getOrgUserFullname(user, includeTitles) || (showEmail ? user.email : '') || '';
  }

}
