import { Inject, Injectable } from '@angular/core';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { IShopItem } from '../../models/shop-item/shop-item.model';
import { SessionGroupRegistrationType } from '../../enums/session-group/session-group-registration-types.enum';
import { SessionFormat } from '../../enums/session-group/session-formats.enum';
import { LocationNamePipe } from '../../pipes/location-name.pipe';
import { ISessionGroup } from '../../models/session-group/session-group.model';
import { ShopItemPaymentMethod } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-price-invoicing/si-pricing-options/si-pricing-options.component';
import { IShopItemSettingModule, ShopItemSettingModuleType } from '../../models/shop-item/shop-item-setting-module.model';
import { ShopItemState } from '../../enums/shop-item/shop-item-states.enum';
import { ShopItemPublicationType } from '../../enums/shop-item/shop-item-publication-types.enum';
import { ShopItemReservationState } from '../../enums/shop-item/shop-item-reservation-states.enum';
import { ISiStatusPresenter } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-state-changer/_components/si-status-presenter/si-status-presenter.component';
import { UtilsService } from '../utils.service';
import { ShopItemReservationType } from '../../enums/shop-item/shop-item-reservation-types.enum';
import { PulseColor } from '../../enums/utils/pulse-colors.enum';
import { ISiStatusIndicator } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-state-changer/_components/si-status-indicator/si-status-indicator.component';
import { IShopItemCustomSettings } from '../../models/shop-item/shop-item-custom-settings.model';

@Injectable({
  providedIn: 'root'
})
export class ShopItemHelperService {

  constructor(
    private localizedDatePipe: LocalizedDatePipe,
    private locationNamePipe: LocationNamePipe,
    private utils: UtilsService,
  ) { }

  getIsCertificatesModuleEnabled(siCustomSettings: IShopItemCustomSettings | null) {
    return !!siCustomSettings?.certificateSettings?.issueCertificates
  }
  
  getIsAttendanceModuleEnabled(siSettingModules: IShopItemSettingModule[] | null) {
    return !!siSettingModules?.find(x => x.type === ShopItemSettingModuleType.ATTENDANCE);
  }

  getSiStatusPresenterData(publicationState: ShopItemState, publicationType: ShopItemPublicationType, reservationsState: ShopItemReservationState, reservationsType: ShopItemReservationType) {
    let indicator: ISiStatusIndicator = {
      colorHex: '',
      pulse: null
    };
    let icon = '';
    let tooltip = '';
  
    if (publicationState === ShopItemState.DRAFT) {
      indicator = {
        colorHex: '#FA8E42',
        pulse: null
      };
      icon = 'pi pi-eye-slash';
      tooltip = 'admin.si-status-presenter.draft';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PUBLIC &&
      reservationsState === ShopItemReservationState.AWAITING_OPENING
    ) {
      indicator = {
        colorHex: '#A0E6BA',
        pulse: null
      };
      icon = 'pi pi-eye';
      tooltip = 'admin.si-status-presenter.published-public-awaiting';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PUBLIC &&
      reservationsState === ShopItemReservationState.OPENED &&
      reservationsType === ShopItemReservationType.PUBLIC
    ) {
      indicator = {
        colorHex: '#1EA97C',
        pulse: PulseColor.GREEN
      };
      icon = 'pi pi-eye';
      tooltip = 'admin.si-status-presenter.published-public-opened-public';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PUBLIC &&
      reservationsState === ShopItemReservationState.PAUSED
    ) {
      indicator = {
        colorHex: '#A0E6BA',
        pulse: null
      };
      icon = 'pi pi-eye';
      tooltip = 'admin.si-status-presenter.published-public-paused';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PRIVATE &&
      (reservationsState === ShopItemReservationState.AWAITING_OPENING || reservationsState === ShopItemReservationState.PAUSED)
    ) {
      indicator = {
        colorHex: '#94E0ED',
        pulse: null
      };
      icon = 'pi pi-link';
      tooltip = 'admin.si-status-presenter.published-public-private-paused';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PRIVATE &&
      reservationsState === ShopItemReservationState.OPENED &&
      reservationsType === ShopItemReservationType.PRIVATE
    ) {
      indicator = {
        colorHex: '#35C4DC',
        pulse: PulseColor.CYAN
      };
      icon = 'pi pi-link';
      tooltip = 'admin.si-status-presenter.published-private-opened-private';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PUBLIC &&
      reservationsState === ShopItemReservationState.OPENED &&
      reservationsType === ShopItemReservationType.PRIVATE
    ) {
      indicator = {
        colorHex: '#41C5B7',
        pulse: PulseColor.TEAL
      };
      icon = 'pi pi-eye';
      tooltip = 'admin.si-status-presenter.published-public-opened-private';
    } else if (
      publicationState === ShopItemState.UNPUBLISHED
    ) {
      indicator = {
        colorHex: '#F6DE95',
        pulse: null
      };
      icon = 'pi pi-eye-slash';
      tooltip = 'admin.si-status-presenter.unpublished';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PUBLIC &&
      reservationsState === ShopItemReservationState.CLOSED
    ) {
      indicator = {
        colorHex: '#FFACA7',
        pulse: null
      };
      icon = 'pi pi-eye';
      tooltip = 'admin.si-status-presenter.published-public-closed';
    } else if (
      publicationState === ShopItemState.PUBLISHED &&
      publicationType === ShopItemPublicationType.PRIVATE &&
      reservationsState === ShopItemReservationState.CLOSED
    ) {
      indicator = {
        colorHex: '#FFACA7',
        pulse: null
      };
      icon = 'pi pi-link';
      tooltip = 'admin.si-status-presenter.published-private-closed';
    } else if (
      publicationState === ShopItemState.FINISHED
    ) {
      indicator = {
        colorHex: '#A855F7',
        pulse: null
      };
      icon = 'pi pi-eye-slash';
      tooltip = 'admin.si-status-presenter.finished';
    } else if (
      publicationState === ShopItemState.CANCELLED
    ) {
      indicator = {
        colorHex: '#9FA9B7',
        pulse: null
      };
      icon = 'pi pi-eye-slash';
      tooltip = 'admin.si-status-presenter.cancelled';
    }
    else {
      indicator = {
        colorHex: '#ffffff',
        pulse: null
      };
      this.utils.logError(`Unhandled si status presenter creation, ${publicationState}, ${publicationType}, ${reservationsState}, ${reservationsType}, 09erugojisdot`);
    }
  
    const presenter: ISiStatusPresenter = {
      indicator,
      tooltip,
      icon
    };

    return presenter;
  }

  getShopItemEntity(shopItem: IShopItem) {
    if (shopItem.sessionGroup) {
      return 'SG';
    } else {
      return 'SI';
    }
  }

  isPayOnSite(shopItem: IShopItem) {
    return shopItem.customSettings.paymentMethod === ShopItemPaymentMethod.ON_SITE;
  }

  hasSettingModule(settingModules: IShopItemSettingModule[], moduleType: ShopItemSettingModuleType) {
    return settingModules.some(m => m.type === moduleType);
  }

  isWpSyncActive(shopItem: IShopItem) {
    return !!shopItem.customSettings.wpSettings?.syncActive;
  }

  getSessionGroupDate(sg: ISessionGroup | undefined | null) {
    if (sg?.registrationType === SessionGroupRegistrationType.ON_DEMAND) return `SessionGroupRegistrationType.ON_DEMAND`;
    if (!sg?.sessions?.length) return null;
  
  
    const firstSession = sg.sessions[0];
    const lastSession = sg.sessions[sg.sessions.length - 1];
  
    return this.getShopItemDate(firstSession.startAt, lastSession.endAt, sg.registrationType);
  }
  getShopItemDate(firstSessionStartAt: string | null, lastSessionEndAt: string | null, sgRegType: SessionGroupRegistrationType | null) {
    if (sgRegType === SessionGroupRegistrationType.ON_DEMAND) return `SessionGroupRegistrationType.ON_DEMAND`;
    if (!firstSessionStartAt || !lastSessionEndAt) return null;

    const firstSessionDay = this.getSessionDay(firstSessionStartAt);
    const firstSessionDate = this.getSessionDate(firstSessionStartAt);
    const lastSessionDate = this.getSessionDate(lastSessionEndAt);

    if (firstSessionDate === lastSessionDate) {
      return `${firstSessionDay} ${firstSessionDate}`;
    } else {
      return `${firstSessionDay} ${firstSessionDate} - ${lastSessionDate}`;
    }
  }

  getSessionGroupDateTimeRange(sg: ISessionGroup | undefined | null) {
    if (sg?.registrationType === SessionGroupRegistrationType.ON_DEMAND) return `SessionGroupRegistrationType.ON_DEMAND`;
    if (!sg?.sessions?.length) return null;
  
    const firstSession = sg?.sessions?.at(0);
    const lastSession = sg?.sessions?.at(-1);
  
    return this.getShopItemDateTimeRange(firstSession?.startAt ?? null, lastSession?.endAt ?? null, sg.registrationType);
  }
  getShopItemDateTimeRange(firstSessionStartAt: string | null, lastSessionEndAt: string | null, sgRegType: SessionGroupRegistrationType | null) {
    if (sgRegType === SessionGroupRegistrationType.ON_DEMAND) return `SessionGroupRegistrationType.ON_DEMAND`;
    if (!firstSessionStartAt || !lastSessionEndAt) return null;

    const firstSessionDayDate = this.getSessionDayDate(firstSessionStartAt);
    const firstSessionTimeRange = this.getSessionTimeRange(firstSessionStartAt, lastSessionEndAt);
    const lastSessionDayDate = this.getSessionDayDate(lastSessionEndAt);

    if (firstSessionDayDate === lastSessionDayDate) {
      return `${firstSessionDayDate} ${firstSessionTimeRange}`;
    } else {
      return `${firstSessionDayDate} ${firstSessionTimeRange} ${lastSessionDayDate}`;
    }
  }

  getSessionGroupTime(sg: ISessionGroup | undefined | null, dates?: { startAt: Date | string | null; endAt: Date | string | null; }) {
    if (sg?.registrationType === SessionGroupRegistrationType.ON_DEMAND) return null;
    if (!sg?.sessions?.length && !dates) return null;

    let startAt: Date | undefined;
    let endAt: Date | undefined;

    if (sg?.sessions?.length) {
      const firstSession = sg.sessions[0];
      startAt = new Date(firstSession.startAt);
      endAt = new Date(firstSession.endAt);
    } else if (dates) {
      startAt = dates.startAt ? new Date(dates.startAt) : undefined;
      endAt = dates.endAt ? new Date(dates.endAt) : undefined;
    }

    if (!startAt || !endAt) return null;

    return this.getSessionTimeRange(startAt, endAt);
  }
  getShopItemTime(firstSessionStartAt: string | null, lastSessionEndAt: string | null, sgRegType: SessionGroupRegistrationType | null) {
    if (sgRegType === SessionGroupRegistrationType.ON_DEMAND) return null;
    if (!firstSessionStartAt || !lastSessionEndAt) return null;

    return this.getSessionTimeRange(firstSessionStartAt, lastSessionEndAt);
  }
  
  getSessionGroupLocation(sg: ISessionGroup | undefined | null, useInternalTitle: boolean) {
    if (sg?.registrationType === SessionGroupRegistrationType.ON_DEMAND) return null;
    if (!sg?.sessions?.length) return null;
  
    const fs = sg.sessions[0];

    return this.getSessionLocation({ format: fs.format, location: fs.location ? { title: fs.location.title, internalTitle: fs.location.internalTitle } : null }, useInternalTitle);
  }
  getShopItemLocation(firstSession: { format: SessionFormat, location: { title: string, internalTitle: string | null } | null } | null, sgRegType: SessionGroupRegistrationType | null, useInternalTitle: boolean) {
    if (sgRegType === SessionGroupRegistrationType.ON_DEMAND) return null;
    if (!firstSession) return null;

    return this.getSessionLocation(firstSession, useInternalTitle);
  }

  getSessionTimeRange(start: string | Date, end: string | Date) {
    return `${this.getSessionTime(start)} – ${this.getSessionTime(end)}`;
  }
  getSessionTime(date: string | Date) {
    return this.localizedDatePipe.transform(date, 'H:mm', undefined, 'UTC+0');
  }
  getSessionDayDate(date: string | Date) {
    return `${this.getSessionDay(date)} ${this.getSessionDate(date)}`;
  }
  getSessionDay(date: string | Date) {
    return this.localizedDatePipe.transform(date, 'EEEEEE', undefined, 'UTC+0')?.toLocaleUpperCase();
  }
  getSessionDate(date: string | Date) {
    return this.localizedDatePipe.transform(date, 'd.M.', undefined, 'UTC+0');
  }
  getSessionLocation(session: { format: SessionFormat, location?: { title: string, internalTitle: string | null } | null }, useInternalTitle: boolean) {
    return this.locationNamePipe.transform(session.location, { useInternalTitle, isOnline: session.format===SessionFormat.ONLINE });
  }
  getSessionYear(date: string | Date) {
    return this.localizedDatePipe.transform(date, 'y', undefined, 'UTC+0');
  }
}
