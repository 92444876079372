import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {}

  transform(value: string | Date | undefined | null, format: string = 'shortDate', lang?: string, timezone?: string, subtractProvidedTimezoneOffset?: string): string | null {
    let val = value;
    if (subtractProvidedTimezoneOffset && val) val = new Date(val).addUtcOffsetSeconds(subtractProvidedTimezoneOffset, true);
    const datePipe = new DatePipe( lang || this.translateService.currentLang );
    const t = datePipe.transform(val, format, timezone);
    return t;
  }

}
