import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MaturityEndEvent } from 'src/app/shared/models/shop-item/shop-item-custom-settings.model';

export enum ShopItemPaymentMethod {
  UPFRONT = 'UPFRONT',
  INSTALLMENTS = 'INSTALLMENT',
  ON_SITE = 'ON_SITE'
}

@Component({
  selector: 'app-si-pricing-options',
  standalone: true,
  imports: [
    CommonModule, SelectButtonModule, RadioButtonModule, ReactiveFormsModule, TranslateModule
  ],
  templateUrl: './si-pricing-options.component.html',
  styleUrls: ['./si-pricing-options.component.scss']
})
export class SiPricingOptionsComponent implements OnInit {

  @Input() freeFC?: FormControl<boolean>;
  @Input() paymentMethodFC?: FormControl<ShopItemPaymentMethod | null>;
  @Input({ required: true }) maturityEndFC!: FormControl<MaturityEndEvent | null>;

  subs: Subscription[] = [];

  pricingOptions: SelectItem[] = [
    {
      value: true,
      label: 'admin.shop-item.form-section.price-invoicing.free-input.free-label'
    },
    {
      value: false,
      label: 'admin.shop-item.form-section.price-invoicing.free-input.paid-label'
    }
  ];

  paymentOptions: SelectItem[] = [
    {
      value: ShopItemPaymentMethod.UPFRONT,
      label: `PaymentMethod.${ShopItemPaymentMethod.UPFRONT}`
    },
    {
      value: ShopItemPaymentMethod.INSTALLMENTS,
      label: `PaymentMethod.${ShopItemPaymentMethod.INSTALLMENTS}`
    },
    {
      value: ShopItemPaymentMethod.ON_SITE,
      label: `PaymentMethod.${ShopItemPaymentMethod.ON_SITE}`
    }
  ];

  ngOnInit(): void {
    this.handleFreeShopItem(this.freeFC?.value || false);
    this.subs.push(
      this.freeFC?.valueChanges.subscribe((free) => {
        this.handleFreeShopItem(free);
      }) || Subscription.EMPTY,
      this.paymentMethodFC?.valueChanges.subscribe((paymentMethod) => {
        if (paymentMethod !== ShopItemPaymentMethod.UPFRONT) {
          this.maturityEndFC.setValue(null);
        }
      }) || Subscription.EMPTY
    );
  }

  private handleFreeShopItem(free: boolean) {
    if (free) {
      this.paymentMethodFC?.setValue(null);
      this.maturityEndFC.setValue(null);
    } else {
      if (this.paymentMethodFC?.getRawValue() === null) {
        this.paymentMethodFC.setValue(ShopItemPaymentMethod.UPFRONT);
      }
    }
  }

  public onPricingOptionSelect(e: any, value: boolean) {
    this.freeFC?.patchValue(value);
  }
}
